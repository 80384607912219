import React from "react";
// import { Link } from "react-router-dom";
import { InvoiceLogo1 } from "../common/imagepath";
import { amountFormat } from "../common/helper";
import AmountToWords from "../common/AmountToWords";
import moment from "moment";
import AsusLogo from "../assets/logos/AsusLogo.png";
import DellLogo from "../assets/logos/Dell.png";
import HPLogo from "../assets/logos/HPLogo.png";
import IntelLogo from "../assets/logos/IntelLogo.png";
import MSILogo from "../assets/logos/MSILogo.png";
import LogitechLogo from "../assets/logos/LogitechLogo.png";
import AcerLogo from "../assets/logos/AcerLogo.png";
import SamsungLogo from "../assets/logos/SamsungLogo.png";
import InvoiceTable from "./InvoiceTable";

const InvoiceOneprint = ({ data, invoiceLogo, currencyData, companyData }) => {
  const dataSource = data.items;

  return (
    <>
      <div
        className="main-wrapper"
        id="element-to-download-as-pdf"
        style={{ fontFamily: "poppins" }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            className="invoice-wrapper download_section"
            style={{
              marginTop: "0", // Removed top margin
              marginBottom: "50px", //Seperated from margin
              boxShadow: "none",
              borderRadius: "0px",
              padding: "15px",
              // width: "793.7px",
              // height: "1122.52px",
              // zoom: "1.1",
            }}
          >
            <div className="inv-content">
              <div className="invoice-header">
                <div className="inv-header-left">
                  <a to="#">
                    <img
                      src={invoiceLogo}
                      onError={(event) => {
                        event.target.src = InvoiceLogo1;
                      }}
                      alt="Logo"
                    />
                  </a>
                  {/* <span>original for recipient</span> */}
                </div>
                <div className="inv-header-right">
                  <div className="invoice-title">Invoice</div>
                  <div>
                    <div style={{ fontSize: "10px" }}>
                      Invoice No:{" "}
                      <span className="invoice-number">
                        {data?.invoiceNumber}
                      </span>
                    </div>
                    <div style={{ fontSize: "10px" }}>
                      Invoice Date:{" "}
                      <span className="invoice-date">
                        {moment(data?.invoiceDate).format("DD-MM-YYYY")}
                      </span>
                    </div>
                    <div style={{ fontSize: "10px" }}>
                      Due Date:{" "}
                      <span className="invoice-date">
                        {moment(data?.dueDate).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="invoice-address-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  textAlign: "left",
                }}
              >
                <div
                  className="invoice-address-box"
                  style={{ width: "48%", fontSize: "10px", textAlign: "left" }}
                >
                  <span className="invoice-address-heading">Billed by</span>
                  <div className="inv-to-address">
                    {companyData?.companyName ? companyData?.companyName : ""}
                    <br />
                    {companyData?.companyAddress
                      ? companyData?.companyAddress
                      : "" || companyData?.addressLine1
                      ? companyData?.addressLine1
                      : ""}
                    {", "}
                    {companyData?.addressLine2 ? companyData?.addressLine2 : ""}
                    {", "}
                    <br />
                    {companyData?.city ? companyData?.city : ""}
                    {", "}
                    {companyData?.country ? companyData?.country : ""}
                    <br />
                    {companyData?.email ? companyData?.email : ""}
                    <br />
                    {companyData?.phone ? companyData?.phone : ""}
                  </div>
                </div>
                <div
                  className="invoice-address-box"
                  style={{ width: "48%", fontSize: "10px", textAlign: "left" }}
                >
                  <span className="invoice-address-heading">Billed to</span>
                  <div className="inv-to-address">
                    {data?.customerId?.name ? data?.customerId?.name : ""}
                    <br />
                    {data?.customerId?.billingAddress?.addressLine1
                      ? data?.customerId?.billingAddress?.addressLine1 + ", "
                      : ""}
                    {data?.customerId?.billingAddress?.city
                      ? data?.customerId?.billingAddress?.city + ","
                      : ""}
                    {data?.customerId?.billingAddress?.addressLine1 ||
                    data?.customerId?.billingAddress?.city ? (
                      <br />
                    ) : (
                      <></>
                    )}
                    {data?.customerId?.billingAddress?.state
                      ? data?.customerId?.billingAddress?.state + ", "
                      : ""}
                    {data?.customerId?.billingAddress?.pincode
                      ? data?.customerId?.billingAddress?.pincode + ", "
                      : ""}
                    {data?.customerId?.billingAddress?.country
                      ? data?.customerId?.billingAddress?.country + "."
                      : ""}
                    {data?.customerId?.billingAddress?.state ||
                    data?.customerId?.billingAddress?.pincode ||
                    data?.customerId?.billingAddress?.country ? (
                      <br />
                    ) : (
                      <></>
                    )}
                    {data?.customerId?.email ? data?.customerId?.email : ""}
                    <br />
                    {data?.customerId?.phone ? data?.customerId?.phone : ""}
                  </div>
                </div>
              </div>
              <div style={{ padding: "12px", minHeight: "465px" }}>
                <InvoiceTable items={dataSource} currencyData={currencyData} />
              </div>
              <div className="invoice-table-footer">
                <div className="table-footer-left" />
                <div className="text-end table-footer-right">
                  <table>
                    <tbody>
                      <tr style={{ fontSize: "10px", height: "2px" }}>
                        <td>Sub Total</td>
                        <td>
                          {currencyData ? `${currencyData} ` : "$"}
                          {Number(
                            (data?.taxableAmount || 0) - (data?.vat || 0)
                          ).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                      <tr style={{ fontSize: "10px", height: "2px" }}>
                        <td>Discount </td>
                        <td>
                          {" "}
                          {currencyData ? `${currencyData} ` : "$"}
                          {Number(data?.totalDiscount).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                      <tr style={{ fontSize: "10px", height: "2px" }}>
                        <td>Due Amount </td>
                        <td>
                          {" "}
                          {currencyData ? `${currencyData} ` : "$"}
                          {Number(data?.dueAmount).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontSize: "10px",
                          fontWeight: 600,
                          color: "black",
                          height: "2px",
                        }}
                      >
                        <td>Total </td>
                        <td>
                          {" "}
                          {currencyData ? `${currencyData} ` : "$"}
                          {Number(data?.TotalAmount).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="total-amountdetails">
                <p style={{ fontSize: "10px" }}>
                  Total amount ( in words):{" "}
                  <AmountToWords amount={amountFormat(data?.TotalAmount)} />
                </p>
              </div>
              <div
                className="terms-condition"
                style={{ textAlign: "left", padding: "15px", fontSize: "10px" }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "10px",
                  }}
                >
                  Terms and Conditions:
                </span>
                {/* <p>{toTitleCase(data?.termsAndCondition)}</p> */}
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li style={{ fontSize: "8px" }}>
                    If the warranty sticker is removed or tampered, the warranty
                    will be void.
                  </li>
                  <li style={{ fontSize: "8px" }}>
                    The warranty will be valid for one year less 14 working days
                    from the date of invoice unless otherwise mentioned above
                    with description.
                  </li>
                  <li style={{ fontSize: "8px" }}>
                    The warranty does not cover physical damages, damage caused
                    by power surge, corrosion, or natural disasters.
                  </li>
                  <li style={{ fontSize: "8px" }}>
                    The warranty does not cover printer heads, cartridges,
                    ribbons, toners, or cables.
                  </li>
                  <li style={{ fontSize: "8px" }}>
                    For warranty claims or service, the item should be carried
                    in to the outlet. On-site warranty is not covered.
                  </li>
                  <li style={{ fontSize: "8px" }}>
                    The original invoice must be produced for warranty claims,
                    and the serial numbers of items must be intact with each
                    item.
                  </li>
                </ul>
              </div>
              <div className="signature-section">
                <div className="signature-box">
                  <div className="signature-box-inner">
                    <div className="signature-line"></div>
                    <span style={{ fontSize: "10px" }}>
                      Signature of the Customer
                    </span>
                  </div>
                </div>
                <div className="signature-box">
                  <div className="signature-box-inner">
                    <div className="signature-line"></div>
                    <span style={{ fontSize: "10px" }}>Authorized By</span>
                  </div>
                </div>
              </div>
              <div className="mainCom-border">
                <div className="thanks-msg text-center">
                  <div className="brand-logos">
                    <img
                      src={AsusLogo}
                      alt="Asus Logo"
                      className="brand-logo"
                    />
                    <img src={DellLogo} alt="DellLogo" className="brand-logo" />
                    <img src={HPLogo} alt="HPLogo" className="brand-logo" />
                    <img
                      src={IntelLogo}
                      alt="IntelLogo"
                      className="brand-logo"
                    />
                    <img src={MSILogo} alt="MSILogo" className="brand-logo" />
                    <img
                      src={LogitechLogo}
                      alt="LogitechLogo"
                      className="brand-logo"
                    />
                    <img src={AcerLogo} alt="AcerLogo" className="brand-logo" />
                    <img
                      src={SamsungLogo}
                      alt="SamsungLogo"
                      className="brand-logo"
                    />
                  </div>
                  {/* Thanks for your Business */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceOneprint;
