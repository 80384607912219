import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import InvoiceOneprint from "./components/InvoiceOnePrinttable";
import Img from "./assets/img/download.png";
import { createRoot } from "react-dom/client";

const styles: { [key: string]: React.CSSProperties } = {
  responsiveContainer: {
    maxWidth: "100%",
    padding: "1px",
    border: "1px solid black",
    margin: "0px,0px,40px",
    backgroundColor: "#f5f5f5",
    fontFamily: "poppins",
    boxSizing: "border-box",
  },
  pdfContainer: {
    width: "794px",
    height: "1123px",
    marginTop: "-20px",
    backgroundColor: "#ffffff",
    fontFamily: "poppins",
    boxSizing: "border-box",
    position: "absolute",
    top: "-9999px",
    margin: "0",
    padding: "0",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#CBC3E3",
    color: "black",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    position: "fixed",
    bottom: "20px",
    left: "20px",
    zIndex: 1000,
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  spinner: {
    width: "50px",
    height: "50px",
    border: "6px solid #ccc",
    borderTop: "6px solid #333",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
};
const BasicDocument = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isinvoice_details_empty, setIsinvoice_details_empty] = useState(false);

  const invoice_id = props?.invoice_id;

  const currencyData = "Rs";
  const invoiceLogo = "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch invoice data
        const invoiceResponse = await fetch(
          `https://api.unitypc.shop/invoice_v2/${invoice_id}`
        );
        if (!invoiceResponse.ok) {
          throw new Error(`Invoice fetch failed: ${invoiceResponse.status}`);
        }
        const data = await invoiceResponse.json();
        if (data?.data?.invoice_details.length === 0) {
          setIsinvoice_details_empty(true);
        }
        setInvoiceData(data?.data?.invoice_details);

        // Fetch company data
        const companyResponse = await fetch(
          `https://api.unitypc.shop/unauthorized/companysettings`
        );
        if (!companyResponse.ok) {
          throw new Error(`Company fetch failed: ${companyResponse.status}`);
        }
        setCompanyData(await companyResponse.json());
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [invoice_id]);

  const fixedRef = useRef(null);

  const downloadAsPdf = async () => {
    if (!invoiceData || !invoiceData.items || invoiceData.items.length === 0) {
      console.error("Invalid or empty invoice data");
      return;
    }
    setIsDownloading(true);
    try {
      const itemsPerPage = 13;
      const totalPages = Math.ceil(invoiceData?.items.length / itemsPerPage);

      // const uniqueItems = invoiceData?.items.map((item, index) => ({
      //   ...item,
      //   key: `${item.serialNumber}-${index}`,
      // }));
      const uniqueItems = invoiceData?.items.map((item, index) => ({
        ...item,
        id: index + 1, // Sequential number for the first column
        key: `${index + 1}-${item.serialNumber || "N/A"}`, // Ensure unique key
      }));
    
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = 210;
      const pdfHeight = 297;

      for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
        const pageItems = uniqueItems.slice(
          pageIndex * itemsPerPage,
          (pageIndex + 1) * itemsPerPage
        );

        const pageData = { ...invoiceData, items: pageItems };

        const tempContainer = document.createElement("div");
        // Object.assign(tempContainer.style, {
        //   width: "794px",
        //   height: "1123px",
        //   marginTop: "-20px",
        //   backgroundColor: "#ffffff",
        //   fontFamily: "poppins",
        //   position: "absolute",
        //   top: "-9999px",
        //   padding: "2px",
        // });
        Object.assign(tempContainer.style, {
          width: "794px",
          height: "1123px",
          padding: "0",
          margin: "0",
          boxSizing: "border-box",
        });
        document.body.appendChild(tempContainer);

        const root = createRoot(tempContainer);
        root.render(
          <InvoiceOneprint
            data={pageData}
            companyData={companyData?.data}
            currencyData={currencyData}
            invoiceLogo={invoiceLogo}
          />
        );

        // Wait for rendering to complete
        await new Promise((resolve) => setTimeout(resolve, 500));

        const canvas = await html2canvas(tempContainer, {
          width: 794,
          height: 1123,
          scale: 3,
          useCORS: true,
          windowWidth: 794,
          scrollY: 0,
        });

        const imgData = canvas.toDataURL("image/png");
        const canvasWidth = canvas.width / 2;
        const canvasHeight = canvas.height / 2;
        const pdfAspectRatio = pdfWidth / pdfHeight;
        const canvasAspectRatio = canvasWidth / canvasHeight;

        let renderWidth = pdfWidth;
        let renderHeight = pdfHeight;

        if (canvasAspectRatio > pdfAspectRatio) {
          renderHeight = pdfWidth / canvasAspectRatio;
        } else {
          renderWidth = pdfHeight * canvasAspectRatio;
        }

        if (pageIndex > 0) {
          pdf.addPage();
        }
        pdf.addImage(
          imgData,
          "PNG",
          (pdfWidth - renderWidth) / 2,
          (pdfHeight - renderHeight) / 2,
          renderWidth,
          renderHeight,
          undefined,
          "FAST"
        );

        // Properly unmount and remove the container
        if (document.body.contains(tempContainer)) {
          root.unmount();
          document.body.removeChild(tempContainer);
        }
      }

      pdf.save("download.pdf");
    } finally {
      setIsDownloading(false);
    }
  };



  useEffect(() => {
    const handleAutoDownloadAndClose = async () => {
      console.log("Window dimensions:", window.innerWidth, window.innerHeight);

      if (window.innerWidth < 600) {
        console.log("Screen size is less than 600px, triggering auto-download.");
        try {
          await downloadAsPdf(); // Wait for the download to complete
          setTimeout(() => {
            console.log("Closing the webpage...");
            window.close(); // Close the webpage
          }, 1000); // Delay for the download completion
        } catch (error) {
          console.error("Error during auto-download:", error);
        }
      } else {
        console.log("Screen size is greater than or equal to 600px, skipping auto-download.");
      }
    };

    handleAutoDownloadAndClose();
  }, []); // Run only once on mount'
  return (
    <>
      {loading || isDownloading ? (
        <div style={styles.spinnerContainer}>
          <div>Loading...</div>
        </div>
      ) : isinvoice_details_empty ? (
        <div style={styles.spinnerContainer}>
          <div>No data found</div>
        </div>
      ) : (
        <>
          <div style={styles.responsiveContainer}>
            <InvoiceOneprint
              data={invoiceData}
              companyData={companyData?.data}
              currencyData={currencyData}
              invoiceLogo={invoiceLogo}
            />
            <button
              style={{
                ...styles.button,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={downloadAsPdf}
            >
              <img
                src={Img}
                alt="Download Icon"
                style={{ width: "20px", height: "20px", marginRight: "8px" }}
              />
              Download
            </button>
          </div>

          <div
            ref={fixedRef}
            style={{
              ...styles.pdfContainer,
              ...{
                padding: "0",
                margin: "0",
                marginTop: "-10px",
              },
            }}
          >
            <InvoiceOneprint
              data={invoiceData}
              companyData={companyData?.data}
              currencyData={currencyData}
              invoiceLogo={invoiceLogo}
            />
          </div>
        </>
      )}
    </>
  );
};

export default BasicDocument;
