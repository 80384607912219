import React from "react";

const InvoiceTable = ({ items, currencyData }) => {
  return (
    <table className="invoice-new-table">
      <thead>
        <tr>
          <th style={{ width: "10%", textAlign: "center" }}>#</th>
          <th style={{ width: "35%", textAlign: "left" }}>
            Item & Description
          </th>
          <th style={{ width: "10%", textAlign: "center" }}>Wty</th>
          <th style={{ width: "10%", textAlign: "center" }}>Qty</th>
          <th style={{ width: "10%", textAlign: "center" }}>Price</th>
          <th style={{ width: "10%", textAlign: "center" }}>Discount</th>
          <th style={{ width: "10%", textAlign: "center" }}>Total</th>
        </tr>
      </thead>
      <tbody>
        {items?.map((item, index) => (
          <tr
            key={item.key || index}
            style={{
              height: "10px",
              lineHeight: "10px",
              padding: "2px 0",
            }}
          >
            {/* <td>{item.key}</td> Changed according to satic id */}
            <td>{index + 1}</td> {/* Display the index, starting from 1 */}
            <td style={{ textAlign: "left" }}>
              {item.name}
              {item?.serialNumber ? ` - ${item?.serialNumber}` : ""}
              {item?.description ? ` - ${item.description}` : ""}
            </td>
            <td>{item?.warranty === "undefined" ? "N/A" : item?.warranty}</td>
            <td>{item.quantity}</td>
            <td>
              {currencyData ? `${currencyData} ` : "$"}
              {Number(item.rate).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {currencyData ? `${currencyData} ` : "$"}{" "}
              {Number(item.discount).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td>
              {currencyData ? `${currencyData} ` : "$"}{" "}
              {Number(item.amount).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoiceTable;
